<template>
  <v-theme-provider>
    <base-section
      id="awards-others"
      space="40"
    >
      <v-container>
        <v-row justify="center">
          <v-col cols="12">
            <base-section-heading title="Penghargaan" />
            <base-body space="0">
               <v-container fluid>
                <v-row dense>
                  <v-col
                  xs="12"
                  md="12"
                  cols="12">
                  <v-select
                      v-model="select"
                      :items="years"
                      label="Pilih Tahun"
                      dense
                      outlined
                    ></v-select>
                  </v-col>
                  <v-col
                    v-for="data in filtered"
                    :key="data.web_news_id"
                    md="4"
                    xs="12"
                    cols="12"
                  >
                    <v-card @click="Detail(data.web_news_id)">
                      <v-img
                        :src="data.web_news_src_url"
                        class="white--text align-end"
                        gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                        height="400px"
                        :alt="data.web_news_name"
                      >
                        <v-card-title v-text="data.web_news_year"></v-card-title>
                      </v-img>
                      <v-card-actions>
                        {{ data.web_news_name }}
                        <v-spacer></v-spacer>
                        <v-btn icon @click="Share(data.web_news_id)">
                          <v-icon>mdi-whatsapp</v-icon>
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
            </base-body>
          </v-col>
        </v-row>
      </v-container>
    </base-section>
  </v-theme-provider>
</template>

<script>
  export default {
    name: 'SectionAwardsOthers',
    data: () => ({
            select: 'Semua',
            posts: [],
            years: [
              'Semua'
            ],
        }),
    created () {
        this.Years()
        this.List()
    },
    computed: {
      filtered () {
        const self = this;
        if (self.select === 'Semua') {
          return self.posts;
        } else {
          return self.posts.filter(function(data) {
            return self.select === data.web_news_year;
          });
        }
      }
    },
    methods: {
        Years () {
          let i
          let today    = new Date()
          let yyyy     = today.getFullYear()
          for (i = yyyy; i >= 2013; i --) {
            this.years.push(i)
          }
        },
        List () {
          this.loading  = true
          let formdata = {
                web_news_id: '',
                web_news_category: 'awards'
          }
          let param      = this.$functions.ParamPOST(formdata)
          this.$axios.post(this.$functions.UrlPOST('apiWebNewsList'),param,{
          headers: {
              'Content-Type': 'text/plain; charset=ISO-8859-1'
          }
          })
          .then(response => {
              this.posts = response.data
              this.loading  = false
          })
          .catch(e => {
              alert(e)
              this.loading  = false
          })
        },
        MaxLength (content, max) {
          let returns = content
          if (content.length >= max) {
            returns = content.substr(0,max) + '... '
          }
          return returns
        },
        Share (id) {
          let paramurl = this.$functions.AppURL() +'/penghargaan-detail/'+id
          window.open('https://wa.me/?text='+encodeURIComponent(paramurl))
        },
        Detail (url) {
          this.$router.push({name: 'penghargaan-detail', params: { url: url }})
        },
    }
  }
</script>
